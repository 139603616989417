<script>
import Layout from "../../layouts/main";
// import ReportStatus from "./wallet-chart.vue";
// import { pieChart } from "./data-apex";
import EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import axios from "axios";
// import imgDummy from "@/assets/images/staff.png";
import base_domain from "../../myvars";
axios.defaults.baseURL = base_domain;

export default {
  components: {
    Layout,
    EasyDataTable,
    // ReportStatus
  },
  data() {
    return {
      verifyes: localStorage.getItem("userType") === "verification officer",
      Email: null,
      first_name: null,
      last_name: null,
      status: null,
      branch: null,
      applicantNum: [],
      assignPrsn: [],
      dateLog: [],
      statusLog: [],
      addStaff: false,
      total_approved: 0,
      total_reject: 0,
      total_incomplete: 0,
      signatureFile: null,
      stampFile: null,
      profilePic: null,
      pp: null,
      // pieChart: pieChart,
      searchValueTable: "",
      selectFilterSort: null,
      headers: [
        { text: "Applicant Number", value: "applicantNum" },
        { text: "Assign", value: "assignPrsn" },
        { text: "Date", value: "dateLog" },
        { text: "Status", value: "statusLog" },
      ],
      items: [],
      arrItem: [],
    };
  },
  created() {
    this.get_staff_data();
    this.filterStatus();
  },
  methods: {
    handleProfileChange(event) {
      const file = event.target.files[0];
      this.convertToBase64(file, (base64) => {
        this.profilePic = base64;
      });
    },
    handleSignatureChange(event) {
      const file = event.target.files[0];
      this.convertToBase64(file, (base64) => {
        this.signatureFile = base64;
      });
    },
    handleStampChange(event) {
      const file = event.target.files[0];
      this.convertToBase64(file, (base64) => {
        this.stampFile = base64;
      });
    },
    convertToBase64(file, callback) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          callback(reader.result);
        };
        reader.onerror = (error) => {
          console.error("Error converting image to Base64:", error);
        };
      }
    },
    submitModal() {
      // alert("I AM WORKING");
      this.updateprofile();
      this.addStaff = false;
    },
    updateprofile() {
      console.log(this.stampFile);
      console.log(this.profilePic);
      console.log(this.signatureFile);

      const token = localStorage.getItem("accessToken");
      axios
        .post("/applications/update_profile/", {
          token: token,
          f_name: this.first_name,
          l_name: this.last_name,
          profile_pic: this.profilePic,
          signature: this.signatureFile,
          stamp: this.stampFile,
        })
        .then((response) => {
          console.log(response);
          // alert("HELLO WORLD");

          this.addStaff = false;
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    filterStatus() {
      console.log("MAI YAHA AA GAYA HU");
      console.log(this.items);
      if (this.selectFilterSort == null) {
        this.arrItem;
      } else {
        this.arrItem = this.items.filter((item) => {
          console.log(item);
          this.arrItem = this.items;
          // return item.statusLog == this.selectFilterSort
        });
      }
    },
    get_staff_data() {
      let arrList = [];
      const app_id = localStorage.getItem("accessToken");

      axios
        .post("/applications/single_User_Details/", { app_id: app_id })
        .then((response) => {
          const data = response.data.user_email;
          console.log("cheack the data came or not ", data);
          (this.first_name = data.first_name),
            (this.last_name = data.last_name),
            (this.status = data.staff_status);
          this.Email = data.email;
          this.branch = data.branch_name;
          this.total_approved = response.data.approved_status;
          this.total_reject = response.data.rejected_status;
          var ts = ("/" + data.pro_image).replace("//", "");
          this.pp = data.pro_image ? base_domain + ts : null;
          // console.log("YE WALA BHI DEKHLO YAR",imageData);
          // const imageURL = `data:image/jpeg;base64, ${imageData}`;
          // console.log("YE WALA BHI DEKHLO YAR",imageURL);
          // this.pp = imageURL;
          let responseList = response.data.user_datw;
          responseList.forEach((item) => {
            console.log(item.status);
            let newItem = {
              applicantNum: item.application_no,
              assignPrsn: `${data.first_name} ${data.last_name}`,
              dateLog: item.created_on,
              statusLog: item.status,
              // user_tok:tok,
              // statusLog:item.status,
              // actionBtnUrl:"#",
            };
            console.log("this is id test ", newItem);
            // Push the transformed item to the existing array
            arrList.push(newItem);
          });
          console.log("NEW ARRAY DATa", arrList);
          this.arrItem = arrList;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <main class="py-4">
      <div class="row">
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <div class="text-center mb-3">
                <img
                  :src="pp === null ? require('@/assets/images/staff.png') : pp"
                  class="m-25 mb-2"
                  style="width: 90px"
                />

                <h5 class="mb-0 font-size-18 fw-medium text-uppercase">{{}}</h5>
                <p class="text-uppercase mb-0 fw-medium font-size-12">
                  Staff Member
                </p>
                <p
                  class="text-uppercase mb-0 fw-medium font-size-12"
                  v-if="verifyes"
                >
                  (Verification Officer)
                </p>
                <a
                  href="javascript:void(0);"
                  class="btn btn-theme-dark"
                  @click="addStaff = true"
                  >Edit Profile</a
                >
              </div>
              <b-modal
                title="Update Profile Info"
                v-model="addStaff"
                hide-footer
                centered
              >
                <form @submit.prevent="submitModal">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="d-block mb-3">
                        <label class="font-size-14">Email </label>
                        <input
                          v-model="Email"
                          type="email"
                          class="form-control simpleInputStyle"
                          placeholder="Email"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="d-block mb-3">
                        <label class="font-size-14">First Name </label>
                        <input
                          v-model="first_name"
                          type="text"
                          class="form-control simpleInputStyle"
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="d-block mb-3">
                        <label class="font-size-14">Last Name </label>
                        <input
                          v-model="last_name"
                          type="text"
                          class="form-control simpleInputStyle"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="d-block mb-3">
                        <label class="font-size-14">Profile Picture </label>
                        <input
                          type="file"
                          class="form-control simpleInputStyle"
                          @change="handleProfileChange"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="d-block mb-3">
                        <label class="font-size-14">Signature</label>
                        <input
                          type="file"
                          class="form-control simpleInputStyle"
                          @change="handleSignatureChange"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="d-block mb-3">
                        <label class="font-size-14">Stamp</label>
                        <input
                          type="file"
                          class="form-control simpleInputStyle"
                          @change="handleStampChange"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="d-block text-end">
                        <input
                          type="submit"
                          class="btn btn-theme btn-custom-wdth"
                          value="Update"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </b-modal>
              <div class="row py-3 border-bottom border-top">
                <div class="col-6 text-center border-end">
                  <h5 class="font-size-20 fw-bold mb-1">
                    {{ total_approved }}
                  </h5>
                  <p class="mb-0">Approved</p>
                </div>
                <div class="col-6 text-center">
                  <h5 class="font-size-20 fw-bold mb-1">{{ total_reject }}</h5>
                  <p class="mb-0">Rejected</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 py-2 border-bottom">
                  <div class="">
                    <div class="d-flex gap-2">
                      <p class="fw-bold mb-0">Email:</p>
                      <p class="mb-0">{{ Email }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 py-2 border-bottom">
                  <div class="">
                    <div class="d-flex gap-2">
                      <p class="fw-bold mb-0">First Name:</p>
                      <p class="mb-0">{{ first_name }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 py-2 border-bottom">
                  <div class="">
                    <div class="d-flex gap-2">
                      <p class="fw-bold mb-0">Last Name:</p>
                      <p class="mb-0">{{ last_name }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 py-2 border-bottom">
                  <div class="">
                    <div class="d-flex gap-2">
                      <p class="fw-bold mb-0">Status:</p>
                      <p class="mb-0">{{ status }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 py-2 border-bottom">
                  <div class="">
                    <div class="d-flex gap-2">
                      <p class="fw-bold mb-0">Branch:</p>
                      <p class="mb-0">{{ branch }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row mb-3 align-items-center">
            <div class="col-md-6">
              <h2 class="font-size-22 text-theme-dark mb-0">
                Related Applications
              </h2>
            </div>
            <div class="col-md-6">
              <div class="page-title-right">
                <nav
                  aria-label="breadcrumb"
                  class="d-flex justify-content-md-end m-0"
                >
                  <ol class="breadcrumb px-0">
                    <li class="breadcrumb-item">
                      <router-link to="/staff_dashboard" class="" target="_self"
                        >Dashboard</router-link
                      >
                    </li>
                    <li class="breadcrumb-item active">
                      <span aria-current="location">My Profile</span>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row mb-3 justify-content-end">
                <div class="col-auto">
                  <!-- <a href="javascript:void(0);" class="btn btn-theme-dark mb-md-0 mb-3">Download
                                        CSV</a> -->
                </div>
                <div class="col-md-4">
                  <select
                    class="form-select form-control simpleSelectStyle"
                    v-model="selectFilterSort"
                    name="favouriteSport"
                    @change="filterStatus"
                  >
                    <option selected :value="null">All Status</option>
                    <option value="Submitted and Under Review">
                      Submitted and Under Review
                    </option>
                    <option value="incomplete">Incomplete</option>
                    <option value="InReview">In Review</option>
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                </div>
              </div>
              <EasyDataTable
                :headers="headers"
                :items="arrItem"
                :search-value="searchValueTable"
                border-cell
                theme-color="#df6a0d"
                :rows-per-page="10"
                buttons-pagination
                table-class-name="table-custom-style"
              >
                <template #item-statusLog="{ statusLog }">
                  <p
                    class="badge badge-pill font-size-11"
                    :class="{
                      'badge-soft-success': `${statusLog}` === 'approved',
                      'badge-soft-danger': `${statusLog}` === 'rejected',
                      'badge-soft-warning': `${statusLog}` === 'incomplete',
                      'badge-soft-secondary': `${statusLog}` === 'submitted',
                      'badge-soft-primary':
                        `${statusLog}` === 'inprogress' ||
                        `${statusLog}` === 'InReview',
                    }"
                  >
                    {{ statusLog }}
                  </p>
                </template>
                <template #item-logdate="{ dateLog, timeLog }">
                  <p class="mb-0 text-nowrap fw-medium">{{ dateLog }}</p>
                  <p class="mb-0 text-nowrap">{{ timeLog }}</p>
                </template>
              </EasyDataTable>
            </div>
          </div>
        </div>
      </div>
    </main>
  </Layout>
</template>